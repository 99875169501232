var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-4"},[_c('v-card-title',[_vm._v("My Products")]),(_vm.moreInformationText)?_c('v-banner',{attrs:{"single-line":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.moreInformationButtonUrl)?_c('v-btn',{attrs:{"text":"","color":"blue darken-1"},on:{"click":function($event){return _vm.navigateToMoreInformation()}}},[_vm._v(_vm._s(_vm.moreInformationButtonText))]):_vm._e()]},proxy:true}],null,false,2355593833)},[_c('v-icon',{attrs:{"slot":"icon","size":"25"},slot:"icon"},[_vm._v(" "+_vm._s(_vm.icons.information)+" ")]),_vm._v(" "+_vm._s(_vm.moreInformationText)+" ")],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.policies,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"text-center ma-12"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)])]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-1",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"clearable":"","flat":"","hide-details":"","prepend-inner-icon":"search","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[(header.tooltip)?_c('span',[_vm._v(_vm._s(header.tooltip))]):_vm._e()])]}}}),{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toLocalTime(item.createdAt))+" ")]}},{key:"item.datasets",fn:function(ref){
var item = ref.item;
return [(item.datasets && item.datasets.length > 0)?_c('v-chip-group',{attrs:{"multiple":"","column":"","active-class":"primary--text"}},[_vm._l((item.datasets.slice(0, 4)),function(dataset){return _c('v-chip',{key:dataset.datasetId,on:{"click":function($event){return _vm.navigateToDataset(dataset.datasetId)}}},[_vm._v(" "+_vm._s(dataset.datasetId)+" ")])}),(item.datasets.length > 4)?_c('v-chip',{on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(" ... ")]):_vm._e()],2):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.navigateToMarketplace(item)}}},on),[_vm._v(" "+_vm._s(_vm.icons.marketplace)+" ")])]}}],null,true)},[_c('span',[_vm._v("Marketplace Page")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.showDetails(item)}}},on),[_vm._v(" "+_vm._s(_vm.icons.search)+" ")])]}}],null,true)},[_c('span',[_vm._v("Plan Details")])])]}}],null,true)}),(_vm.showError)?_c('Dialog',{attrs:{"title":_vm.errorDialogTitle,"text":_vm.errorDialogText,"cancelButtonEnabled":false},on:{"confirmed":function($event){_vm.showError = false}},model:{value:(_vm.showError),callback:function ($$v) {_vm.showError=$$v},expression:"showError"}}):_vm._e(),(_vm.showProductDetail)?_c('v-dialog',{attrs:{"max-width":"650"},model:{value:(_vm.showProductDetail),callback:function ($$v) {_vm.showProductDetail=$$v},expression:"showProductDetail"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Product Details "),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.navigateToMarketplace(_vm.selectedItem)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icons.marketplace))]),_vm._v(" Marketplace ")],1)],1),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.selectedItemSummary)}}),_c('form',{staticClass:"px-4"},[_c('v-expansion-panels',{model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[(
              this.selectedItem.bigQueryEnabled === true &&
                this.selectedItem.datasets &&
                this.selectedItem.datasets.length > 0 &&
                this.selectedItem.isTableBased === false
            )?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("BigQuery Datasets")]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.datasetHeaders,"items":this.selectedItem.datasets,"search":_vm.datasetSearch,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"text-center ma-12"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)])]},proxy:true},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.datasetSearch),callback:function ($$v) {_vm.datasetSearch=$$v},expression:"datasetSearch"}})]},proxy:true},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.navigateToDataset(item.datasetId)}}},on),[_vm._v(" "+_vm._s(_vm.icons.databaseSearch)+" ")])]}}],null,true)},[_c('span',[_vm._v("Navigate to dataset")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.copyQueryToClipboard(item.datasetId)}}},on),[_vm._v(" "+_vm._s(_vm.icons.contentCopy)+" ")])]}}],null,true)},[_c('span',[_vm._v("Copy query to clipboard")])])]}}],null,true)})],1)],1):_vm._e(),(
              this.selectedItem.bigQueryEnabled === true &&
                this.selectedItem.isTableBased === true
            )?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("BigQuery Tables")]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":this.availableTables,"search":_vm.tableSearch,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"text-center ma-12"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)])]},proxy:true},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})]},proxy:true},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.navigateToTable(item.datasetId, item.tableId)}}},on),[_vm._v(" "+_vm._s(_vm.icons.tableHeadersEye)+" ")])]}}],null,true)},[_c('span',[_vm._v("Navigate to table")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.copyQueryToClipboard(item.datasetId, item.tableId)}}},on),[_vm._v(" "+_vm._s(_vm.icons.contentCopy)+" ")])]}}],null,true)},[_c('span',[_vm._v("Copy query to clipboard")])])]}}],null,true)})],1)],1):_vm._e(),(this.selectedItem.storageEnabled === true)?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Cloud Storage Buckets")]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.storageHeaders,"items":this.selectedItem.buckets,"search":_vm.storageSearch,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"text-center ma-12"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)])]},proxy:true},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.storageSearch),callback:function ($$v) {_vm.storageSearch=$$v},expression:"storageSearch"}})]},proxy:true},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.navigateToBucket(item.bucketName)}}},on),[_vm._v(" "+_vm._s(_vm.icons.bucketOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Navigate to Bucket")])])]}}],null,true)})],1)],1):_vm._e(),(this.selectedItem.pubsubEnabled === true)?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Pub/Sub Topics")]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.topicHeaders,"items":this.selectedItem.topics,"search":_vm.topicSearch,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"text-center ma-12"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)])]},proxy:true},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.topicSearch),callback:function ($$v) {_vm.topicSearch=$$v},expression:"topicSearch"}})]},proxy:true},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.navigateToCreateSubscription()}}},on),[_vm._v(" "+_vm._s(_vm.icons.newspaperVariantMultiple)+" ")])]}}],null,true)},[_c('span',[_vm._v("Navigate to create a subscription")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.copyTopicNameToClipboard(item.topicId)}}},on),[_vm._v(" "+_vm._s(_vm.icons.contentCopy)+" ")])]}}],null,true)},[_c('span',[_vm._v("Copy full resource id of the topic to the clipboard to be used for creating a new subscription")])])]}}],null,true)})],1)],1):_vm._e(),(
              this.selectedItem.rowAccessTags &&
                this.selectedItem.rowAccessTags.length > 0
            )?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Row Filters")]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.rowAccessHeaders,"items":this.selectedItem.rowAccessTags,"search":_vm.rowAccessSearch,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"text-center ma-12"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)])]},proxy:true},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.rowAccessSearch),callback:function ($$v) {_vm.rowAccessSearch=$$v},expression:"rowAccessSearch"}})]},proxy:true},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" edit ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")])]}}],null,true)})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeDetailDialog()}}},[_vm._v("OK")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }