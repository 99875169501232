<template>
  <MyProductsCard />
</template>

<script>
import MyProductsCard from '@/components/MyProductsCard';

export default {
  components: {
    MyProductsCard
  }
};
</script>
